import '../styles/about.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function About(){
    AOS.init({
        duration: 500,
      });

    return(
        <div className='container mb-5' id='about'>
            <div className='aboutcenter'>
                <h3 className='colorhead'>About Us</h3>
            </div>
            <div className="cards cardee">
                    <div className="card-body">
                        <div>
                            <span></span>
                            <div>
                                <h4 className='colorhead'>VIRTILENS</h4>
                                <p> VirtiLens is an award winning creative and technology studio that specialises in VR, AR and AI solutions. Apart from expertise in state of the art software and hardware, VirtiLens, being one of the first movers in the space, brings years of experience that enables the company to conceptualize, ideate and deliver accurate solutions within a short turn around period.</p>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="cards cardee">
                    <div className="card-body">
                        <div>
                            <span></span>
                            <div>
                                <h4 className='colorhead'>V20 CRICKET</h4>
                                <p>V20 Cricket is the best Virtual Reality cricket game for all ages! 
                                    Welcome to the future of cricket. In this immersive Virtual cricket simulator, you can bat in different match scenarios, chase down targets and be the master of your own stadium! 
                                    You’re front and centre, in a true to life stadium with panoramic 360 degree views. World-class bowling, phenomenal fielding and a 100,000 roaring fans with just two overs to go ⁃ Are you up for it? Of course you are, the virtual world is your oyster!<br></br>
                                    
                                </p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default About;