import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/gallery.css';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {  Autoplay, Pagination, Navigation} from "swiper";

function IndividualIntervalsExample() {
  AOS.init({
    duration: 500,
  });
  return (
    <div className='mb-5 mar' id='gallery'>
      <div className=' container gallerycenter'>
          <h3 className='colorhead'>Gallery</h3>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper container"
      >
        <SwiperSlide><img src={require("../assets/screen10.jpg")} alt="gallery"/></SwiperSlide>
        <SwiperSlide><img src={require("../assets/screen5.jpg")} alt="gallery"/></SwiperSlide>
        <SwiperSlide><img src={require("../assets/screen6.jpg")} alt="gallery"/></SwiperSlide>
        <SwiperSlide><img src={require("../assets/screen7.jpg")} alt="gallery"/></SwiperSlide>
        <SwiperSlide><img src={require("../assets/screen8.jpg")} alt="gallery"/></SwiperSlide>
        <SwiperSlide><img src={require("../assets/v20cricket.jpg")} alt="gallery"/></SwiperSlide>
      </Swiper>
    </div>
  );
}

export default IndividualIntervalsExample;