import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import '../styles/header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
const changeBackground = () =>{
        if(window.scrollY >= 120){
            setNavbar(true);
        }else{
            setNavbar(false);
        }
    }


    window.addEventListener('scroll', changeBackground);

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <div id='home'>
    <Navbar className="colored" collapseOnSelect expand="lg" fixed="top" variant="dark">
      <Navbar.Brand href="#home">
        <img src={require("../assets/v20cricket.jpg")} alt="" width={90}/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsOpen(!isOpen)}/>
      <Navbar.Collapse id="responsive-navbar-nav" className={isOpen ? 'show' : ''}>
        <Nav className="mx-auto"></Nav>
        <Nav >
          <Nav.Link href="#home" onClick={closeNavbar}><span className='last'>Home</span></Nav.Link>
          <Nav.Link href="#about" onClick={closeNavbar}><span className='last'>About</span></Nav.Link>
          <Nav.Link href="#gallery" onClick={closeNavbar}><span className='last'>Gallery</span></Nav.Link>
          <Nav.Link href="#contact" onClick={closeNavbar}><span className='last'>Contact</span></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  );
}

export default Header;