import AOS from 'aos';
import { useState } from 'react';
import 'aos/dist/aos.css';
import '../styles/home.css';
import Header from './header';

function Home() {
  AOS.init({
    duration: 1000,
  });

  const [navbar, setNavbar] = useState(false);
  const changeBackground = () =>{
          if(window.scrollY >= 570){
              setNavbar(true);
          }else{
              setNavbar(false);
          }
      }
  
  
  window.addEventListener('scroll', changeBackground);


  return (
    <>
    <div className=''>
      <Header/>
    </div>
      <div className='banner1' id='home'>
        <div className={navbar ? 'hider' : 'down colorhead'}>
           <a href='http://www.oculus.com/experiences/quest/4280506722037298/' className='loder'>Download<img src={require("../assets/oculus.png")} className="imager" width={60} height={30} alt="oculus"/></a>
        </div>
      </div>
    </>
  );
}

export default Home;