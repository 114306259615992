import Home from './pages/home';
import About from './pages/about';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import Powered from './pages/powered';
import './App.css';

function App() {
  return (
    <>
      <Home/>
      <About/>
      <Gallery/>
      <Contact/>
    </>
   
  );
}

export default App;
