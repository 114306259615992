import React from "react";
import emailjs from "@emailjs/browser";
import AOS from 'aos';
import '../styles/contact.css';
import 'aos/dist/aos.css';
import Ending from "../pages/footer";

const Contact = () => {

  AOS.init({
    duration: 500,
  });
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      
      emailjs.sendForm(
          "gmail",
          "template_r8k3daq",
          e.target,
          "Wm2IdYj0qvFyn2DmL"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
        e.target.reset();
    };
  
    return (
      <>
        <div id="contact" className="paddbot">
            <div className='container'>
                <div className='aboutcenter'>
                    <h3 className="colorhead">Contact Us</h3>
                </div>
          </div>
          <div className="container d-flex justify-content-md-center justify-content-lg-start flex-column flex-lg-row flex-sm-column">
            <div className="p-3">
              <div className="">
                <div>
                  <h3 className="d-flex justify-content-center colorhead align-items-center">Get in touch with us!</h3>
                  <small>We'll send you epic updates,event information, franchising options and a lot more!</small>
                </div>
                <div className="mt-5 mb-1">
                  <span className="colorhead">Address:</span>
                  <span className="spacehead">No: 17, North Railway Terminus Road, Royapuram, Chennai, Tamil Nadu, India - 600 013.</span>
                </div>
                <div className="mb-1">
                  <span className="colorhead">Phone:</span>
                  <span className="spacehead">+91 8754499240 | +91 8124594203</span>
                </div>
                <div className="mb-1">
                  <span className="colorhead">Email:</span>
                  <span className="spacehead">info@virtilens.com</span>
                </div>
              </div>
            </div>
              <form onSubmit={sendEmail}>
                <div className="p-3 w-100 card shadow-lg p-3 bg-body-tertiary rounded">
                  <div className="mb-3">
                    <input type="text" className="form-control" id="exampleFormControlInput1" name="Name" placeholder="Your name"/>
                  </div>
                  <div className="mb-3">
                      <input type="email" className="form-control" id="exampleFormControlInput1" name="E-mail" placeholder="Your E-mail"/>
                  </div>
                  <div className="mb-3">
                      <input type="number" className="form-control" id="exampleFormControlInput1" name="Phone-number" placeholder="Your Phone Number"/>
                  </div>
                  <div className="mb-3">
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="message" placeholder="Text Message"></textarea>
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="button"><span>Send</span></button>
                  </div>
                </div>
              </form>
          </div>
        </div>
        <div>
          <Ending/>
        </div>
      </>
    );
  };
  
  export default Contact;