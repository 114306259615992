import React from "react";
import '../styles/footer.css';

function Footer(){

    return(
        <footer>
            
            <ul className="social-icon">
                <li className="socialicon">
                    <a href="https://www.facebook.com/profile.php?id=100076057720450"><ion-icon name="logo-facebook"></ion-icon></a>
                </li>
                <li className="socialicon">
                    <a href="https://www.instagram.com/v20cricket/"><ion-icon name="logo-instagram"></ion-icon></a>
                </li>
                <li className="socialicon">
                    <a href="https://twitter.com/LensVirti"><ion-icon name="logo-twitter"></ion-icon></a>
                </li>
                <li className="socialicon">
                    <a href="https://youtu.be/CSFR4ebgets"><ion-icon name="logo-youtube"></ion-icon></a>
                </li>
            </ul>
            <p>COPYRIGHT © 2019. ALL RIGHTS RESERVED BY V20 CRICKET POWERED BY <a href="https://www.virtilens.com/" className="company">VIRTILENS</a></p>
                <ul className="social-icon">
                    <li className="socialicon1">
                        <a href="https://gamepolicy.s3.ap-south-1.amazonaws.com/privacypolicy.html" className="company">Privacy</a>
                    </li>
                </ul>
        </footer>
    );
}

export default Footer;